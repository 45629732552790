import React, { useState, useEffect } from 'react';
import { useReactiveVar } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { ReturnsForm } from 'ui/component/rma/returns-form';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import { SalesLayout } from '../../layouts/sales-layout';
import {
    ISalesLayoutConfig,
    salesLayoutConfigVar,
} from '../../layouts/sales-layout/sales-layout-state';
import {
    salesGuestValidOrderResultVar,
} from '../../layouts/guest-layout/guest-layout-state';
import styles from './style.css';


const ReturnFormPage: React.FC = () => {
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const config: ISalesLayoutConfig = useReactiveVar(salesLayoutConfigVar);
    const salesGuestValidOrderResult = useReactiveVar(salesGuestValidOrderResultVar);

    const returnFromPageComponent: JSX.Element = isLoaded ? (
        <div className={styles.returnFormPage}>
            <h1 className={styles.returnFormPageTitle}>{t('Returns')}</h1>
            <div className={styles.returnFormDisclaimerText}>
                <p className={styles.text}>
                    {t('Within 30 days of purchase, we\'re happy to accept returns.')}
                </p>
                <p className={styles.text}>
                    {t('Additional conditions apply to embroidered, altered, & used products.')}
                </p>
            </div>
            <ReturnsForm config={config} />
        </div>
    ) : (<></>);

    useEffect(() => {
        if (!salesGuestValidOrderResult) {
            return;
        }

        const { result, redirectUrl } = salesGuestValidOrderResult;
        const isRedirectToAccount = !result && redirectUrl.includes('sales/order/history');
        const isRedirectToGuestOrder = result && !!redirectUrl.length;

        if (isRedirectToAccount || isRedirectToGuestOrder) {
            navigateToUrl({
                navigate,
                url: redirectUrl,
            });
            return;
        }

        setIsLoaded(true);
    }, [salesGuestValidOrderResult]);

    return (
        <SalesLayout
            pageKey="return-form-page"
            pageTitle={t('Returns')}
            pageContent={returnFromPageComponent}
            showLeftSidebar={false}
            isGuestPage
            salesLayoutClassName={styles.returnFormLayout}
        />
    );
};

export { ReturnFormPage as default };
