import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { usePhraseTranslater } from '@silkpwa/module/i18n';
import { classes } from '@silkpwa/module/util/classes';
import { Navigate, useNavigate } from '@silkpwa/module/router/use-navigate';
import { navigateToUrl } from 'ui/util/navigate-to-url';
import {
    IGuestFindOrderInput,
    SALES_GUEST_FIND_ORDER,
} from 'graphql/guest/salesGuestFindOrder';
import {
    ISalesLayoutConfig,
} from 'ui/page/sales-pages/layouts/sales-layout/sales-layout-state';
import { ActionButton } from 'ui/component/custom/action-button';
import { InputField } from 'ui/component/custom/input-field';
import { Messages } from 'ui/component/custom/messages';
import { IOption, Select } from 'ui/component/custom/select';
import { RmaRequestForm } from 'ui/component/sales-components/rma-request-form';
import { isEmailValid, sanitizeInput } from 'ui/util/validator-helper';
import styles from './style.css';

interface IReturnsFormProps {
    config: ISalesLayoutConfig;
}

export const ReturnsForm: React.FC<IReturnsFormProps> = ({ config }) => {
    const [isDisabled, setIsDisabled] = useState(false);
    const [showFormUrl, setShowFormUrl] = useState(false);
    const [validateOnSubmit, setValidateOnSubmit] = useState(false);
    const [submitInProgress, setSubmitInProgress] = useState(false);
    const [isSubmitError, setIsSubmitError] = useState(false);
    const [errorMessages, setErrorMessages] = useState<string[]>([]);
    const [isSubmitSuccess, setIsSubmitSuccess] = useState(false);
    const [successMessages, setSuccessMessages] = useState<string[]>([]);
    const [orderNumber, setOrderNumber] = useState<string>('');
    const [orderNumberStatus, setOrderNumberStatus] = useState<'default'|'invalid'>('default');
    const [lastName, setLastName] = useState<string>('');
    const [lastNameStatus, setLastNameStatus] = useState<'default'|'invalid'>('default');
    const [findBy, setFindBy] = useState<string>('');
    const [findByStatus, setFindByStatus] = useState<'default'|'invalid'>('default');
    const [email, setEmail] = useState<string>('');
    const [emailStatus, setEmailStatus] = useState<'default'|'invalid'>('default');
    const [zip, setZip] = useState<string>('');
    const [zipStatus, setZipStatus] = useState<'default'|'invalid'>('default');

    const t = usePhraseTranslater();
    const navigate: Navigate = useNavigate();

    const { salesConfig } = config;
    const { guestOrderUrl, rmaConfig } = salesConfig;
    const { rmaFormUrl } = rmaConfig;

    const findByOptions: IOption[] = [
        {
            value: 'email',
            label: t('EMAIL ADDRESS'),
        },
        {
            value: 'zip',
            label: t('ZIP CODE'),
        },
    ];

    const getSelectedFindBy = (): IOption|undefined => findByOptions.find(option => option.value === findBy);

    const isTextValid = (value: string, required = true) => required && value.trim() !== '';

    const isFieldValid = (
        type: 'text'|'email',
        sanitizedValue: string,
    ): boolean => {
        let isFieldValid: boolean;
        switch (type) {
            case 'email':
                isFieldValid = isTextValid(sanitizedValue) && isEmailValid(sanitizedValue);
                break;
            case 'text':
            default:
                isFieldValid = isTextValid(sanitizedValue);
                break;
        }

        return isFieldValid;
    };

    const validateField = (
        type: 'text'|'email',
        value: string,
        setStatus: (status: 'default'|'invalid') => void,
        setValue?: (value: string) => void,
    ): void => {
        const sanitizedValue = sanitizeInput(value);
        const validationResult = isFieldValid(type, sanitizedValue);
        if (setValue) {
            setValue(sanitizedValue);
        }

        setStatus(!validateOnSubmit || validationResult ? 'default' : 'invalid');
    };

    const validateForm = (): boolean => {
        const isOrderNumberValid = isFieldValid('text', orderNumber);
        const isLastNameValid = isFieldValid('text', lastName);
        const isFindByValid = isFieldValid('text', findBy);

        let isChosenFindByValid = false;

        if (findBy === 'email') {
            isChosenFindByValid = isFieldValid('email', email);
        }

        if (findBy === 'zip') {
            isChosenFindByValid = isFieldValid('text', zip);
        }

        return (
            isOrderNumberValid &&
            isLastNameValid &&
            isFindByValid &&
            isChosenFindByValid
        );
    };

    const onOrderNumberChange = (value: string): void => {
        validateField('text', value, setOrderNumberStatus, setOrderNumber);
    };

    const onLastNameChange = (value: string): void => {
        validateField('text', value, setLastNameStatus, setLastName);
    };

    const onFindByChange = (value: string): void => {
        validateField('text', value, setFindByStatus, setFindBy);
    };

    const onEmailChange = (value: string): void => {
        validateField('email', value, setEmailStatus, setEmail);
    };

    const onZipChange = (value: string): void => {
        validateField('text', value, setZipStatus, setZip);
    };

    const validateAllFields = () => {
        validateField('text', orderNumber, setOrderNumberStatus);
        validateField('text', lastName, setLastNameStatus);
        validateField('text', findBy, setFindByStatus);
        if (findBy === 'email') {
            validateField('email', email, setEmailStatus);
        }

        if (findBy === 'zip') {
            validateField('text', zip, setZipStatus);
        }
    };

    const [salesGuestFindOrder] = useLazyQuery(SALES_GUEST_FIND_ORDER, {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
        onError: ({ graphQLErrors }) => {
            setIsSubmitSuccess(false);
            setIsSubmitError(true);
            const errorMessages: string[] = graphQLErrors.map((graphQLError) => {
                const { message } = graphQLError;
                return message;
            });
            setErrorMessages(errorMessages);
        },
        onCompleted: (data): void => {
            const { salesGuestFindOrder } = data;
            const {
                result,
                canShowFormUrl,
                orderId,
                errorMessage,
            } = salesGuestFindOrder;
            const orderIdValid = parseInt(orderId, 10) > 0;
            const isSuccess = result && orderIdValid;

            if (isSuccess) {
                setSuccessMessages([t('Order is found. You will be redirected to Your Order Page in 5 seconds.')]);
                setTimeout(() => {
                    navigateToUrl({
                        navigate,
                        url: guestOrderUrl,
                    });
                }, 5500);
            }

            if (!isSuccess) {
                if (errorMessage.length > 0) {
                    setErrorMessages([errorMessage]);
                }

                if (canShowFormUrl) {
                    setShowFormUrl(true);
                }
            }

            setIsSubmitSuccess(isSuccess);
            setIsSubmitError(!isSuccess);
        },
    });

    const findValidOrderRequest = async () => {
        const variables: IGuestFindOrderInput = {
            orderNumber,
            lastName,
            findBy,
            email,
            zip,
        };
        await salesGuestFindOrder({ variables });
    };

    const resetSubmit = () => {
        setErrorMessages([]);
        setSuccessMessages([]);
        setIsSubmitError(false);
        setIsSubmitSuccess(false);
        setShowFormUrl(false);
    };

    const onSubmitForm = (event?: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent): void => {
        if (event) event.preventDefault();
        resetSubmit();
        if (!validateForm()) {
            setValidateOnSubmit(true);
            return;
        }

        setSubmitInProgress(true);
        findValidOrderRequest().finally(() => {
            setSubmitInProgress(false);
        });
    };

    useEffect(() => {
        if (validateOnSubmit) {
            validateAllFields();
        }
    }, [validateOnSubmit]);

    useEffect(() => {
        if (validateOnSubmit) {
            const isFormValid = validateForm();
            if (isFormValid) {
                setValidateOnSubmit(false);
            }
        }
    }, [orderNumber, lastName, findBy, email, zip]);

    useEffect(() => {
        if (validateOnSubmit) {
            const isFormValid = validateForm();
            setIsDisabled(!isFormValid || submitInProgress);
        } else {
            setIsDisabled(submitInProgress || isSubmitSuccess);
        }
    }, [validateOnSubmit, submitInProgress, isSubmitSuccess]);

    return (
        <div className={classes(styles.createReturnsWrapper)}>
            <div className={styles.returnFormFields}>
                <InputField
                    inputId="oar-order-id"
                    inputName="oar_order_id"
                    inputLabel={t('ORDER #')}
                    onChange={onOrderNumberChange}
                    status={orderNumberStatus}
                    required
                />
                <InputField
                    inputId="oar-billing-lastname"
                    inputName="oar_billing_lastname"
                    inputLabel={t('BILLING LAST NAME')}
                    onChange={onLastNameChange}
                    status={lastNameStatus}
                    required
                />
                <div className={classes(styles.findBy, styles.required)}>
                    <div
                        className={classes(styles.label, {
                            [styles.invalid]: findByStatus === 'invalid',
                        })}
                    >
                        <span>{t('FIND ORDER BY')}</span>
                    </div>
                    <div className={styles.control}>
                        <Select
                            options={findByOptions}
                            selected={getSelectedFindBy()}
                            onChange={onFindByChange}
                            status={findByStatus}
                            className={styles.selectWrapper}
                            placeholderClassName={styles.selectPlaceholder}
                            selectClassName={styles.select}
                        />
                    </div>
                </div>
                {findBy === 'email' && (
                    <InputField
                        inputId="oar-email"
                        inputName="oar_email"
                        inputLabel={t('EMAIL ADDRESS')}
                        onChange={onEmailChange}
                        status={emailStatus}
                        required
                    />
                )}
                {findBy === 'zip' && (
                    <InputField
                        inputId="oar-zip"
                        inputName="zip"
                        inputLabel={t('BILLING ZIP CODE')}
                        onChange={onZipChange}
                        status={zipStatus}
                        required
                    />
                )}
            </div>
            <div className={styles.submitWrapper}>
                {showFormUrl && (
                    <RmaRequestForm
                        rmaFormUrl={rmaFormUrl}
                        rmaFormLabel={t('If you don\'t remember order details, you may create a return using:')}
                        className={styles.returnFormInfo}
                    />
                )}
                <ActionButton
                    btnClassName={styles.submitBtn}
                    label={submitInProgress ? t('IN PROGRESS...') : t('CONTINUE')}
                    disabled={isDisabled}
                    action={onSubmitForm}
                />
                <Messages
                    mainWrapperClassName={styles.formMessages}
                    successMessages={successMessages}
                    showSuccessMessages={isSubmitSuccess}
                    setShowSuccessMessages={setIsSubmitSuccess}
                    hideSuccessMessagesDelay={4500}
                    errorMessages={errorMessages}
                    showErrorMessages={isSubmitError}
                    setShowErrorMessages={setIsSubmitError}
                    hideErrorMessagesDelay={3500}
                />
                <div className={styles.submitInfo}>
                    {t('You Will be Redirected To Your Order Page')}
                </div>
            </div>
        </div>
    );
};
