import React from 'react';
import { classes } from '@silkpwa/module/util/classes';
import styles from './style.css';

interface IInputFieldProps {
    inputId?: string;
    inputName?: string;
    inputLabel?: string;
    placeholder?: string;
    fieldClassName?: string;
    labelClassName?: string;
    controlClassName?: string;
    inputClassName?: string;
    onChange?: (value: string) => void;
    required?: boolean;
    status?: 'default' | 'invalid';
}

export const InputField: React.FC<IInputFieldProps> = (
    {
        inputId,
        inputName,
        inputLabel,
        placeholder,
        fieldClassName,
        labelClassName,
        controlClassName,
        inputClassName,
        onChange,
        required = false,
        status = 'default',
    },
) => {
    const generateId = (): string => Date.now().toString(36) + Math.random().toString(36).substr(2);

    const uid = generateId();

    const customInputId = inputId || `id-${uid}`;
    const customInputName = inputName || `name_${uid}`;

    const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { target } = event;
        const { value } = target;

        if (onChange) {
            onChange(value);
        }
    };

    return (
        <div
            className={classes(styles.field, (fieldClassName || ''), {
                [styles.required]: required,
                [styles.invalid]: status === 'invalid',
            })}
        >
            {inputLabel && (
                <label className={classes(styles.label, (labelClassName || ''))} htmlFor={customInputId}>
                    <span>{inputLabel}</span>
                </label>
            )}
            <div className={classes(styles.control, (controlClassName || ''))}>
                <input
                    id={customInputId}
                    className={classes(styles.inputText, (inputClassName || ''))}
                    type="text"
                    name={customInputName}
                    placeholder={(placeholder || '')}
                    required={required}
                    onChange={handleOnChange}
                />
            </div>
        </div>
    );
};
