import { gql, TypedDocumentNode } from '@apollo/client';

export interface ISalesGuestFindOrderResult {
    result: boolean;
    canShowFormUrl: boolean;
    orderId: string;
    errorMessage: string;
}

export interface IGuestFindOrderOutput {
    salesGuestFindOrder: ISalesGuestFindOrderResult;
}

export interface IGuestFindOrderInput {
    orderNumber: string;
    lastName: string;
    findBy: string;
    email: string;
    zip: string;
}

export const SALES_GUEST_FIND_ORDER: TypedDocumentNode<IGuestFindOrderOutput, IGuestFindOrderInput> = gql`
    query SALES_GUEST_FIND_ORDER(
        $orderNumber: String!
        $lastName: String!
        $findBy: String!
        $email: String
        $zip: String
    ) {
        salesGuestFindOrder(
            orderNumber: $orderNumber,
            lastName: $lastName,
            findBy: $findBy,
            email: $email,
            zip: $zip,
        ) {
            result
            canShowFormUrl
            orderId
            errorMessage
        }
    }
`;
